<template>
    <div class="video-management">
        <div class="video-management-content">
            <div class="video-management-header">
                <span>视频发布</span>
            </div>
            <div class="select-content">
                <div class="item">
                    <span class="sort-text">平台：</span>
                    <div class="img-item" :class="{current: currentPublishPlatform === selectItem.id}"
                         v-for="selectItem in selectPublishPlatform"
                         :key="selectItem.id"
                         @click="choosePublishPlatform(selectItem)">
                        <img class="type-img" :src="selectItem.src">
                        <img class="img-item-selected"
                             src="../../../assets/img/image/platform-selected.png">
                    </div>
                </div>
                <div class="item item-account">
                    <span class="sort-text">账号：</span>
                    <div class="img-item" :class="{current: currentAccount === selectItem.id}"
                         v-for="selectItem in selectPublishAccount"
                         :key="selectItem.id"
                         @click="choosePublishAccount(selectItem.id)">
                        <img class="type-img" :src="selectItem.avatar">
                        <img class="img-item-selected"
                             src="../../../assets/img/image/platform-selected.png">
                    </div>
                </div>
                <el-form :model="publishVideoForm" ref="publishVideoForm" label-position="center" class="info-form">
                    <el-form-item label="视频内容:">
                        <div class="add-video" v-if="parseInt(this.$route.query.ShortTab) === 1">
                            <div class="up-img-show" v-show="publishVideoForm.video">
                                <span>{{publishVideoForm.videoFileName}}</span>
                                <span class="video-text">{{publishVideoForm.videoFileSize}}M</span>
                            </div>
                            <el-button type="primary" v-model="publishVideoForm.video" class="upload-btn-video" size="medium">
                                <span>{{publishVideoForm.video ? '重新上传' : '上传视频'}}</span>
                                <input type="file" accept="video/*" id="videoId" @change="changeVideo($event)">
                            </el-button>
                        </div>
                        <div class="video-show">
                            <video :src="publishVideoForm.videoUrl" controls="controls"></video>
                        </div>
                        <div class="video-request">
                            <span>1.支持常用视频格式，推荐使用mp4、webm</span>
                            <span>2.视频文件大小不超过20M，时长在15分钟以下</span>
                            <span>3.分辨率为720p(1280×720)及以上</span>
                        </div>
                    </el-form-item>
                    <el-form-item>

                    </el-form-item>
                    <el-form-item label="上传封面:">
                        <canvas width="300" height="150" style="background-color: #ECEBF9;"></canvas>
                        <!--<div v-if="publishVideoForm.coverSrc" class="cover-img">-->
                            <!--<div class="img">-->
                                <!--<img :src="publishVideoForm.coverSrc">-->
                            <!--</div>-->
                            <!--<ImgCutter v-on:cutDown="saveCropCover" v-if="publishVideoForm.coverSrc">-->
                                <!--<el-button type="primary" slot="open" class="upload-btn-video" size="medium">-->
                                    <!--<span>重新上传</span>-->
                                <!--</el-button>-->
                            <!--</ImgCutter>-->
                        <!--</div>-->
                        <!--<img id="cover_img" crossorigin="Anonymous">-->
                        <!--裁剪封面弹窗-->
                        <!--<ImgCutter v-on:cutDown="saveCropCover" v-on:onChooseImg="changeImg" v-if="publishVideoForm.coverSrc === null">-->
                            <!--<div class="video-cover" slot="open">-->
                                <!--<i class="el-icon-circle-plus"></i>-->
                                <!--<div class="el-upload__text">点击上传</div>-->
                            <!--</div>-->
                        <!--</ImgCutter>-->
                        <div class="video-request">
                            <span>1.默认上传视频的第一帧为封面</span>
                            <span>2.格式jpg、jpeg、png，文件大小≤5MB，最低尺寸≥960*600</span>
                        </div>
                    </el-form-item>
                    <el-form-item label="视频标题:">
                        <el-input v-model="publishVideoForm.videoTitle" autocomplete="off" placeholder="请输入视频标题" class="video-title-input"></el-input>
                        <el-button type="primary" class="add-video-title" size="medium" @click="addVideoTopic">#添加话题</el-button>
                    </el-form-item>
                    <!--<el-form-item label="发布时间:">-->
                        <!--<el-switch-->
                                <!--v-model="publishTimeValue"-->
                                <!--active-color="#13ce66"-->
                                <!--inactive-color="#ff4949">-->
                        <!--</el-switch>-->
                        <!--<el-date-picker-->
                                <!--v-model="publishVideoForm.publishTime"-->
                                <!--type="datetime"-->
                                <!--placeholder="选择日期时间"-->
                                <!--class="publish-time">-->
                        <!--</el-date-picker>-->
                    <!--</el-form-item>-->
                    <el-form-item class="publish-btn">
                        <el-button type="primary" @click="onSubmit">发布</el-button>
                        <el-button @click="cancelPublish">取消</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
    import ImgCutter from 'vue-img-cutter'
    import {mapGetters} from 'vuex';

    export default {
        name: "VideoPublish",
        data() {
            return {
                //平台选择
                selectPublishPlatform: [
                    {
                        id: 1,
                        name: '抖音',
                        src: require('../../../assets/img/image/platform-1.png')
                    },
                    {
                        id: 2,
                        name: '快手',
                        src: require('../../../assets/img/image/platform-2.png')
                    },
                    {
                        id: 3,
                        name: '哔哩哔哩',
                        src: require('../../../assets/img/image/platform-3.png')
                    },
                    {
                        id: 4,
                        name: '美拍',
                        src: require('../../../assets/img/image/platform-6.png')
                    },
                    {
                        id: 5,
                        name: '西瓜',
                        src: require('../../../assets/img/image/platform-5.png')
                    },
                    {
                        id: 6,
                        name: '火山视频',
                        src: require('../../../assets/img/image/platform-4.png')
                    }
                ],
                currentPublishPlatform: 1,
                //账号列表
                selectPublishAccount: [],
                currentAccount: null,
                //发布表单
                publishVideoForm: {
                    id: '',
                    video: '',
                    video_id: '',
                    videoFile: '',
                    videoFileName: '',
                    videoFileSize: '',
                    videoUrl: this.$route.query.file_path || '',
                    coverSrc: null,
                    videoTitle: '',
                    publishTime: ''
                },
                //发布时间开关
                publishTimeValue: true,
                //从剪辑库传过来的视频路径
                clip_video_path: '',
                clip_video_path_src: ''
            }
        },
        components:{
            ImgCutter
        },
        computed: {
            ...mapGetters(['filePath','filePathUrl'])
        },
        mounted() {
            this.getPlatformAccount();
        },
        methods: {
            //选择平台
            choosePublishPlatform(item) {
                this.currentPublishPlatform = item.id;
            },
            //选择账号
            choosePublishAccount(id) {
                this.currentAccount = id;
            },
            //获取平台账号
            getPlatformAccount() {
                let params = {
                    platform_type: this.currentPublishPlatform,
                    type: 1
                }
                this.$httpStudent.axiosGetBy(this.$api.get_account, params, (res) => {
                    if (res.code === 200) {
                        this.selectPublishAccount = res.data.data;
                        if (res.data.data.length > 0) {
                            this.currentAccount = res.data.data[0].id;
                            if (this.filePath) {
                                this.publishVideoForm.video_id = this.$route.query.vId;
                                this.clip_video_path = this.filePath;
                                this.clip_video_path_src = this.filePathUrl;
                                if (this.$route.query.ShortTab === 2) {
                                    this.uploadVideo();
                                }
                                this.getVideoCover();
                            }
                        }
                    } else {
                        this.$message.error(res.message);
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //上传视频
            changeVideo(event) {
                if (event.target.files.length === 0) return;
                if (event.target.files[0].size > 1024 * 1024 * 20) {
                    this.$message.warning('视频大小超过20M');
                    return;
                } else {
                    let fileList = event.target.files[0];
                    this.publishVideoForm.videoFile = fileList;
                    this.publishVideoForm.videoFileName = fileList.name;
                    this.publishVideoForm.videoFileSize = (fileList.size / (1024 * 1024)).toFixed(2);
                    let url = '';
                    let reader = new FileReader();
                    reader.readAsDataURL(fileList);
                    let that = this;
                    reader.onload = function (e) {
                        url = this.result.substring(this.result.indexOf(',') + 1);
                        that.publishVideoForm.video = "data:video/*," + url;
                    }
                    event.target.value = '';
                    this.uploadVideo();
                }
            },
            uploadVideo() {
                let formData = new FormData();
                formData.append('account_id', this.currentAccount);
                //本地上传
                if (this.publishVideoForm.videoFile) {
                    formData.append('file', this.publishVideoForm.videoFile);
                }
                //剪辑库上传
                if (this.clip_video_path_src) {
                    formData.append('url', this.clip_video_path_src);
                }
                new Promise((resolve, reject) => {
                    this.$httpStudent.axiosPost(this.$api.tikhoknew_data_video, formData, (res) => {
                        if (res.code === 200) {
                            this.publishVideoForm.video_id = res.data.video.video_id;
                            this.publishVideoForm.videoUrl = res.data.url;
                            resolve(res.data.url);
                        }
                    }, (err) => {
                        console.log(err);
                        reject(err);
                    })
                }).then(res => {
                    this.getVideoCover();
                })

            },
            //获取视频第一帧
            getVideoCover() {
                var video = null;
                var canvas = null;
                video = document.querySelector('video');
                canvas = document.querySelector('canvas');
                var ctx = canvas.getContext('2d');
                setTimeout(() => {
                    ctx.drawImage(video, 0, 0, 500,500);
                }, 1000);
            },
            //选择图片后
            changeImg($event) {
                let file = $event.file;
                if (file) {
                    const isImg = file.type('.jpg') || file.type('.jpeg') || file.type('.png');
                    const isLt2M = file.size / 1024 / 1024 <= 5;
                    if (!isImg) {
                        this.$message.error('上传图片只能是 jpg 或 jpeg 或 png 格式!');
                        return false;
                    }
                    if (!isLt2M) {
                        this.$message.error('上传图片大小小于等于 5MB!');
                        return false;
                    }
                }
            },
            //调出裁剪封面弹窗
            saveCropCover($event) {
                this.publishVideoForm.coverSrc = $event.dataURL;
            },
            //添加话题
            addVideoTopic() {
                this.publishVideoForm.videoTitle += '#';
            },
            //发布
            onSubmit() {
                if (!this.publishVideoForm.videoUrl) {
                    if (!this.publishVideoForm.video) {
                        this.$message.warning('还没上传视频哦~')
                    }
                }

                if (!this.publishVideoForm.videoTitle) {
                    this.$message({
                        message: '视频标题不能为空哦~',
                        offset: 60,
                        type: 'warning'
                    });
                }
                let formData = new FormData();
                formData.append('account_id', this.currentAccount);
                formData.append('video_id', this.publishVideoForm.video_id);
                formData.append('text', this.publishVideoForm.videoTitle);
                formData.append('url', this.publishVideoForm.videoUrl);
                if (Number(this.$route.query.ShortTab) === 1) {
                    //本地发布
                    formData.append('local', 1);
                } else {
                    //剪辑库发布
                    formData.append('local', 2);
                }

                this.$httpStudent.axiosPost(this.$api.tikhoknew_data_public, formData, (res) => {
                    if (res.code === 200) {
                        this.$router.push({
                            path: '/student/video/management',
                        });
                        this.$message.success(res.message);
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //取消发布
            cancelPublish() {
                this.$router.push({
                    path: '/student/video/management',
                });
            }
        }
    }
</script>

<style scoped lang="scss">
    .video-management-content {
        background-color: #fff;
        padding-top: 35px;
        .video-management-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            margin: 0 35px;
            padding-bottom: 19px;
            .publish-video {
                background-color: #594FED;
                border-color: #594FED;
                border-radius: 15px;
                &:hover {
                    background-color: #463bdc;
                    border-color: #463bdc;
                }
            }
        }
        .select-content {
            ::v-deep .el-checkbox-group {
                display: flex;
                .img-item {
                    .el-checkbox__label {
                        line-height: 10px;
                        img {
                            width: 38px;
                            height: 38px;
                        }
                    }
                }
            }
            ::v-deep .el-checkbox {
                width: 63px !important;
                display: flex;
                justify-content: start;
                align-items: center;
            }
            padding: 0 35px 0 45px;
            .item {
                display: flex;
                align-items: center;
                padding: 0 10px;
                margin: 20px 0 20px 0;
                color: #666;
            }
            .item-account {
                border-bottom: 1px solid #D1D2DB;
                padding-bottom: 20px;
                ::v-deep .el-checkbox-group {
                    .el-checkbox__input.is-checked .el-checkbox__inner {
                        background-color: #9ACC5C;
                        border-color: #9ACC5C;
                        &:hover {
                            border-color: #9ACC5C;
                        }
                    }
                    .el-checkbox__inner {
                        &:hover {
                            border-color: #9ACC5C;
                        }
                    }
                }
            }
            .sort-item {
                display: inline-block;
                padding: 2px 6px;
                margin: 0 5px;
            }
            .sort-item.current, .sort-item:hover {
                background: #9ACC5B;
                color: #fff;
                border-radius: 5px;
                cursor: pointer;
            }
            .img-item {
                background: transparent !important;
                width: 38px;
                height: 38px;
                border-radius: 50% !important;
                margin-right: 20px;
                cursor: pointer;
                img.type-img {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                }
                img.img-item-selected {
                    display: none;
                    position: relative;
                    top: -15px;
                    left: 27px;
                    width: 14px;
                    height: 14px;
                }
            }
            .current {
                img.img-item-selected {
                    display: block;
                }
            }
            .info-form {
                min-height: calc(100vh - 346px);
                padding-bottom: 10px;
                .add-video {
                    display: flex;
                    align-items: center;
                    margin-bottom: 20px;
                    .up-img-show {
                        margin-right: 200px;
                        .video-text {
                            color: #BCB9CB;
                            margin-left: 20px;
                        }
                    }
                }
                .video-request {
                    display: flex;
                    flex-direction: column;
                    color: #BCB9CB;
                    font-size: 14px;
                    margin-left: 75px;
                }
                .video-cover {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    width: 120px;
                    height: 120px;
                    background: #ECEBF9;
                    border-radius: 10px;
                    cursor: pointer;
                    i {
                        color: #9ACC5B;
                        font-size: 22px;
                    }
                    .el-upload__text {
                        font-size: 12px;
                        color: #5F5C74;
                    }
                }
                .video-show {
                    margin: 0 0 10px 75px;
                    width: 300px;
                    height: 150px;
                    video {
                        width: 100%;
                        height: 100%;
                    }
                }
                .cover-img {
                    display: flex;
                    align-items: center;
                    .img {
                        width: 450px;
                        height: 300px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-right: 10px;
                        img {
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }
                }
                .video-title-input {
                    width: 270px;
                    margin-right: 10px;
                    ::v-deep .el-input__inner {
                        width: 270px;
                        height: 30px;
                        line-height: 30px;
                        border-radius: 10px;
                    }
                }
                .add-video-title {
                    padding: 6px 12px;
                    background-color: #BCB9CB;
                    border-color: #BCB9CB;
                    font-size: 12px;
                    border-radius: 10px;
                    &:hover {
                        background-color: #b1b0b7;
                        border-color: #b1b0b7;
                    }
                }
                .publish-time {
                    ::v-deep .el-input__inner {
                        height: 30px;
                        line-height: 30px;
                        border-radius: 10px;
                        margin-left: 10px;
                    }
                    ::v-deep .el-input__prefix {
                        left: 15px;
                    }
                }
                .upload-btn-video {
                    position: relative;
                    padding: 7px 16px;
                    border-radius: 15px;
                    background: #594FEE;
                    border-color: #594FEE;
                    cursor: pointer;
                    &:hover {
                        background: #2338E6;
                        border-color: #2338E6;
                    }
                    input {
                        position: absolute;
                        right: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        opacity: 0;
                        cursor: pointer;
                    }
                }
                .publish-btn {
                    ::v-deep .el-button {
                        padding: 7px 30px;
                        border-radius: 15px;
                        cursor: pointer;
                    }
                    .el-button--primary {
                        background: #594FEE;
                        border-color: #594FEE;
                        &:hover {
                            background: #2338E6;
                            border-color: #2338E6;
                        }
                    }
                    .el-button--default {

                    }
                }
            }
        }
    }
</style>